import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import FenceAboutMain from 'components/layout/Sections/Fences/FenceAboutMain'
import Tiles from 'components/layout/Sections/Pages/KolekcjaLinea/Tiles'

import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Kolekcja Linea',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Kolekcja Linea',
    },
  ],
}

const KolekcjaLineaPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.kolekcje
  const PAGE_SEO = data?.wpPage?.seo

  const LINEA_FENCE_MODELS = React.useMemo(
    () =>
      PAGE?.collectionFenceModels?.map((model) => ({
        img: model?.collectionFenceModelsModelImg?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: model?.collectionFenceModelsModelImg?.altText,
        label: model?.collectionFenceModelsModelName,
        link: `${model?.collectionFenceModelsModelLink?.toLowerCase()}/`,
      })),
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.collectionImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.collectionImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        headingMobile={PAGE?.collectionTitle}
        headingDesktop={PAGE?.collectionTitle}
        breadcrumbs={breadcrumbs}
      />
      <FenceAboutMain
        description1={PAGE?.collectionDescriptionLeft}
        description2={PAGE?.collectionDescriptionRight}
      />
      <Tiles gallery={LINEA_FENCE_MODELS} />
    </Layout>
  )
}

export default KolekcjaLineaPage

export const query = graphql`
  query KolekcjaLineaPage {
    wpPage(slug: { regex: "/kolekcja-linea/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      id
      kolekcje {
        collectionDescriptionLeft
        collectionDescriptionRight
        collectionTitle
        collectionImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        collectionFenceModels {
          collectionFenceModelsModelImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1024
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          collectionFenceModelsModelName
          collectionFenceModelsModelLink
        }
      }
    }
  }
`
